import React, { Component } from 'react';

class TextWithTitle extends Component {

  render() {
    const { title, children } = this.props;
    return (
      <p>
        <b>{title}</b> {children}
      </p>
    );
  }
}


export default TextWithTitle;
